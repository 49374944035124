import { Col, Container, Image, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next"
import anastylose from '../public/images/Clients/client-anastylose.png'
import structural from '../public/images/Clients/client-structural.png'
import total from '../public/images/Clients/client-total.png'
import proBTP from '../public/images/Clients/client-ProBtp.png'

function Clients() {
    const { t } = useTranslation();
    return (
        <>
            <h1 className="Title-primary">{t('customers.title')}</h1>
            <Container fluid>
                <Row>
                    <Col md={3}>
                        <Image width="50%" src={structural} rounded />
                    </Col>
                    <Col md={3}>
                        <Image width="50%" src={anastylose} rounded />
                    </Col>
                    <Col md={3}>
                        <Image width="30%" src={total} rounded />
                    </Col>
                    <Col md={3}>
                        <Image width="50%" src={proBTP} rounded />
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Clients;